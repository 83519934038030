import {
  BaseStepIdEnum,
  FieldEntityTypeEnum,
  FieldPropertyTypeEnum,
  FieldTypeEnum,
  StepConfig,
  StepTypeEnum,
} from 'shared-domain';

export const stepsConfig: StepConfig[] = [
  {
    id: 'disclaimer',
    type: StepTypeEnum.text,
  },
  {
    id: BaseStepIdEnum.company_search,
  },
  {
    id: BaseStepIdEnum.company_list,
  },
  {
    id: BaseStepIdEnum.company_edit,
  },
  {
    id: BaseStepIdEnum.affiliated_companies_list,
  },
  {
    id: BaseStepIdEnum.individuals_list,
    config: { hasApplicant: true },
  },
  // Enable the individual_edit step only for a KYC
  /*{
    id:  BaseStepIdEnum.individual_edit,
  },*/
  {
    id: 'investment_intentions',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'expected_trading_volume',
          type: FieldTypeEnum.select,
          isRequired: true,
          isEnabled: true,
          options: [
            'between_100000_and_250000',
            'between_250000_and_500000',
            'between_500000_and_1000000',
            'more_than_1000000',
          ],
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'wallet_address',
          type: FieldTypeEnum.text,
          hasHelper: true,
          isEnabled: true,
          isRequired: false,
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'primary_usage_intent',
          type: FieldTypeEnum.select,
          isEnabled: true,
          isRequired: true,
          options: [
            'saving_in_bitcoin',
            'transfer_of_funds',
            'online_payment',
            'decentralized_currency_preferency',
          ],
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
      ],
    },
  },
  {
    id: 'marketing_optin',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'optin_for_marketing_content',
          type: FieldTypeEnum.checkbox,
          isEnabled: true,
          isRequired: false,
          options: ['yes'],
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
      ],
    },
  },
];
